import React, { useEffect, useState } from "react";
import { get } from "lodash";

import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import PageWrapper from "src/components/ui/PageWrapper";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";

import UsersTableRow from "src/features/user/UsersTableRow";

import { IListParams } from "src/app/apiConfig";

import { IUser } from "src/common/ts/user";
import { AppRoutesEnum } from "src/common/ts/enums";

import UserFilters from "./UserFilters";
import { useUser } from "./user.hooks";
import UserDetails from "./UserDetails";

const TABLE_HEADERS = ["#", "Dane", "Rejestracja", "Typ", "Subskrypcja", ""];

const Users = () => {
  const [filteringData, setFilteringData] = useState<IListParams>({});
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const { handleGetUsers, isLoading, users } = useUser();

  useEffect(() => {
    handleGetUsers(filteringData);
  }, [filteringData]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderRows = () => {
    if (!users) return null;
    return users.documents.map((d, i) => {
      return <UsersTableRow data={d} index={i} key={d._id} onShowDetails={setSelectedUser} />;
    });
  };

  return (
    <PageWrapper>
      <UserDetails onClosePanel={() => setSelectedUser(null)} user={selectedUser} />
      <ModuleContentContainer isSidebarActive={!!selectedUser}>
        <div className="d-flex content-header justify-content-between align-items-center">
          <h3 className="font-weight-bold m-0">Użytkownicy</h3>
          <UserFilters onChange={setFilteringData} />
        </div>
        <hr />
        <section className="scroll-section" id="breakpointSpecificResponsive">
          <div className="card">
            <div className="card-body p-0">
              <Table headers={TABLE_HEADERS} isLoading={isLoading} rows={renderRows()} />
              <Pagination
                filteringData={filteringData}
                onChange={setFilteringData}
                route={AppRoutesEnum.Users}
                total={get(users, "total", 1)}
              />
            </div>
          </div>
        </section>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Users;
