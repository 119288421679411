import { createApi } from "@reduxjs/toolkit/query/react";
import { IListParams, customBaseQuery } from "src/app/apiConfig";
import {
  IBaseCreateResponse,
  IBaseDeleteDocumentRequest,
  IBaseListResponse,
  IBaseOkResponse,
} from "src/common/ts/base";
import { ICreateSubscriptionRequest, ISubscription, IUpdateSubscriptionRequest } from "src/common/ts/subscription";

export const subscriptionApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    createSubscription: build.mutation<IBaseCreateResponse<ISubscription>, ICreateSubscriptionRequest>({
      invalidatesTags: ["Subscriptions"],
      query: (data) => ({
        body: data,
        method: "POST",
        url: `subscription`,
      }),
    }),
    deleteSubscription: build.mutation<IBaseOkResponse, IBaseDeleteDocumentRequest>({
      invalidatesTags: ["Subscriptions"],
      query: ({ id }) => ({
        method: "DELETE",
        url: `subscription/${id}`,
      }),
    }),
    getSubscriptions: build.query<IBaseListResponse<ISubscription>, IListParams>({
      providesTags: ["Subscriptions"],
      query: (query) => ({
        params: { ...query, "sort[name]": "asc" },
        url: `subscription`,
      }),
    }),
    updateSubscription: build.mutation<IBaseCreateResponse<ISubscription>, IUpdateSubscriptionRequest>({
      invalidatesTags: ["Subscriptions"],
      query: ({ id, ...data }) => ({
        body: data,
        method: "PUT",
        url: `subscription/${id}`,
      }),
    }),
  }),
  reducerPath: "subscriptionApi",
  tagTypes: ["Subscriptions"],
});

export const {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useLazyGetSubscriptionsQuery,
  useUpdateSubscriptionMutation,
} = subscriptionApi;
