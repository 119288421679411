import React, { useState } from "react";

type Props = {
  id: string;
  placeholder?: string;
  label?: string;
  rows?: number;
  cols?: number;
  error?: string | undefined;
  register: any;
  isSubmited?: boolean;
  labelClasses?: string;
};

const AppTextarea: React.FC<Props> = ({ id, placeholder, rows, cols, error, register, isSubmited }) => {
  const [hideError, setHideError] = useState(false);
  const inputClasses = `form-control ${error ? "is-invalid" : ""} ${isSubmited && !error ? "is-valid" : ""}`;

  const handleFocus = () => {
    setHideError(true);
    document.getElementById(id)?.focus();
  };

  return (
    <>
      <textarea
        {...register(id)}
        className={inputClasses}
        cols={cols}
        onBlur={() => setHideError(false)}
        onFocus={() => handleFocus()}
        placeholder={placeholder}
        rows={rows}
      />
      {error && !hideError && (
        <div className="invalid-tooltip" onClick={() => setHideError(true)}>
          {error}
        </div>
      )}
    </>
  );
};

export default AppTextarea;
