import { useEffect } from "react";
import { toast } from "react-toastify";

import { IListParams } from "src/app/apiConfig";

import { useAddInvoiceMutation, useLazyGetOrderInvoiceQuery, useLazyGetOrdersQuery } from "./orderApi";

export const useOrder = () => {
  const [getOrders, { data: orders, isFetching, error }] = useLazyGetOrdersQuery();
  const [getOrderInvoice, { data: getInvoiceData, error: invoiceError }] = useLazyGetOrderInvoiceQuery();
  const [addInvoice, { data: addInvoiceData, isLoading: addInvoiceLoading, error: addInvoiceError }] =
    useAddInvoiceMutation();

  useEffect(() => {
    if (error || invoiceError || addInvoiceError) {
      toast.error("Wystąpił błąd");
    }
  }, [error, invoiceError, addInvoiceError]);

  useEffect(() => {
    if (addInvoiceData) {
      toast.success("Zapisano");
    }
  }, [addInvoiceData]);

  useEffect(() => {
    if (getInvoiceData) {
      toast.success("Pobrano dokument");
    }
  }, [getInvoiceData]);

  return {
    addInvoice: async (body: File, id: string) => {
      await addInvoice({ body, id });
    },
    addInvoiceData,
    addInvoiceLoading,
    handleGetOrderInvoice: async (orderId: string, invoiceUrl: string) => {
      await getOrderInvoice({ fileName: invoiceUrl.split("/").pop(), id: orderId });
    },
    handleGetOrders: async (listParams: IListParams) => {
      await getOrders(listParams);
    },
    isLoading: isFetching,
    orders,
  };
};
