import React from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";

registerLocale("pl", pl);

type Props = {
  error?: string;
  field: any;
};

const AppDatePicker: React.FC<Props> = ({ error, field }) => {
  return (
    <div className="col-12">
      <DatePicker
        className="form-control"
        dateFormat="dd/MM/yyyy"
        locale="pl"
        onChange={(date: Date) => field.onChange(date)}
        placeholderText="dd.mm.yyyy"
        portalId="root-portal"
        selected={field.value}
      />
      {error && <div className="invalid-tooltip">{error}</div>}
    </div>
  );
};

export default AppDatePicker;
