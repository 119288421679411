import React from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppInput, { InputTypes } from "src/components/form/AppInput";

import { IUserFilteringData } from "src/common/ts/user";
import LabeledContainer from "src/components/ui/LabeledContainer";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";

import AppDatePicker from "src/components/ui/AppDatePicker";
import { IListParams } from "src/app/apiConfig";

import UserFiltersSchema from "./userFilters.schema";

type Props = {
  onChange: React.Dispatch<React.SetStateAction<IListParams>>;
};

const UserFilters: React.FC<Props> = ({ onChange }) => {
  const { control, register, handleSubmit } = useForm<IListParams>({
    resolver: yupResolver(UserFiltersSchema),
    reValidateMode: "onChange",
  });

  const parseData = (data: IUserFilteringData) => {
    const { dateFrom, dateTo, name } = data;

    const parsedData: IListParams = {};
    if (name) parsedData.name = name;
    if (dateFrom) parsedData["createdAt[gte]"] = moment(dateFrom).unix();
    if (dateTo) parsedData["createdAt[lte]"] = moment(dateTo).add(1, "day").unix();

    onChange(parsedData);
  };

  return (
    <div className="ms-md-3 mt-3 mt-md-0 justify-content-md-end flex-wrap row g-3 d-flex">
      <LabeledContainer customClass="col-12 col-md-3" title="Użytkownik">
        <AppInput id="name" inputType={InputTypes.TEXT} register={register} />
      </LabeledContainer>
      <LabeledContainer customClass="col-6 col-md-3" title="Data utworzenia od">
        <Controller control={control} name="dateFrom" render={({ field }) => <AppDatePicker field={field} />} />
      </LabeledContainer>
      <LabeledContainer customClass="col-6 col-md-3" title="Data utworzenia do">
        <Controller control={control} name="dateTo" render={({ field }) => <AppDatePicker field={field} />} />
      </LabeledContainer>
      <AppButton
        color={ButtonColorTypes.VINCI}
        customClass="col-12 col-md-3 filtry"
        onClick={handleSubmit(parseData)}
        title="Filtruj"
      />
    </div>
  );
};

export default UserFilters;
