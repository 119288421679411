import React from "react";

import { isEmpty } from "lodash";
import { ThreeDots } from "react-loader-spinner";

type Props = {
  headers: string[];
  rows?: React.ReactNode[] | null;
  noDataMsg?: string;
  isLoading?: boolean;
};

const Table: React.FC<Props> = ({ headers, rows, noDataMsg, isLoading }) => {
  const renderHeaders = () => {
    return headers.map((header: string) => {
      return (
        <th key={header} scope="col">
          {header}
        </th>
      );
    });
  };

  const renderRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={headers?.length}>
            <ThreeDots
              ariaLabel="three-dots-loading"
              color="#4fa94d"
              height="80"
              radius="9"
              visible={isLoading}
              width="80"
              wrapperStyle={{ justifyContent: "center" }}
            />
          </td>
        </tr>
      );
    }

    if (!rows || isEmpty(rows)) {
      return (
        <tr>
          <td className="border-0 text-center" colSpan={headers.length}>
            <div className="empty-list d-flex justify-content-center flex-column align-items-center">
              <p className="font-weight-light">{noDataMsg || "-"}</p>
            </div>
          </td>
        </tr>
      );
    }

    return rows.map((row: React.ReactNode) => {
      return row;
    });
  };
  return (
    <div className="table-responsive-sm mb-5">
      <table className="table">
        <thead>
          <tr className="bg-light text-small">{renderHeaders()}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default Table;
