import { useEffect } from "react";
import { toast } from "react-toastify";

import { IListParams } from "src/app/apiConfig";
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationsQuery,
  useUpdateNotificationMutation,
} from "src/features/notification/notificationApi";
import { ICreateNotificationRequest, IUpdateNotificationRequest } from "src/common/ts/notification";

export const useNotification = () => {
  const [getNotifications, { data: notifications, error: fetchListError, isLoading: fetchListLoading }] =
    useLazyGetNotificationsQuery();
  const [createNotification, { data: createData, isLoading: createLoading, error: createError }] =
    useCreateNotificationMutation();
  const [updateNotification, { data: updateData, isLoading: updateLoading, error: updateError }] =
    useUpdateNotificationMutation();
  const [deleteNotification, { data: deleteRes, error: deleteError }] = useDeleteNotificationMutation();

  useEffect(() => {
    if (createData || updateData) {
      toast.success("Zapisano");
    }
  }, [createData, updateData]);

  useEffect(() => {
    if (fetchListError || createError || updateError || deleteError) {
      toast.error("Wystąpił błąd");
    }
  }, [fetchListError, createError, updateError, deleteError]);

  useEffect(() => {
    if (deleteRes) {
      toast.success("Usunięto");
    }
  }, [deleteRes]);

  return {
    createLoading,
    createNotification: async (data: ICreateNotificationRequest) => {
      await createNotification(data);
    },
    deleteNotification: async (id: string) => {
      await deleteNotification({ id });
    },
    fetchListLoading,
    fetchNotifications: async (listParams: IListParams) => {
      await getNotifications(listParams);
    },
    notifications,
    updateLoading,
    updateNotification: async ({ id, ...data }: IUpdateNotificationRequest) => {
      await updateNotification({ ...data, id });
    },
  };
};
