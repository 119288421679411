import React from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";

import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import { IResetPasswordRequest } from "src/common/ts/resetPassword.interface";
import AuthWrapper from "src/components/ui/AuthWrapper";

import LockIcon from "src/assets/images/Lock.svg";

import ResetPasswordSchema from "./resetPassword.schema";
import { useResetPassword } from "./resetPassword.hooks";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { handleResetPassword, error } = useResetPassword();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitted },
  } = useForm<IResetPasswordRequest>({
    defaultValues: {
      code,
    },
    resolver: yupResolver(ResetPasswordSchema),
    reValidateMode: "onChange",
  });

  return (
    <AuthWrapper subtitle="Lorem ipsum dolor sit amet." title="Poznałeś już nasze nowości?">
      <div className="pt-5">
        <div className="tooltip-end-bottom text-center" id="loginForm">
          <div className="mb-3 filled form-group tooltip-end-top">
            <AppInput
              error={formErrors.password?.message || get(error, "data.errors.password")}
              icon={LockIcon}
              id="password"
              inputType={InputTypes.PASSWORD}
              isSubmited={isSubmitted}
              placeholder="Hasło"
              register={register}
            />
          </div>
          <div className="mb-3 filled form-group tooltip-end-top">
            <AppInput
              error={formErrors.passwordConfirm?.message || get(error, "data.errors.passwordConfirm")}
              icon={LockIcon}
              id="passwordConfirm"
              inputType={InputTypes.PASSWORD}
              isSubmited={isSubmitted}
              placeholder="Powtórz hasło"
              register={register}
            />
          </div>
          <AppButton
            color={ButtonColorTypes.VINCI}
            customClass="btn-xl btn-primary mb-5"
            onClick={handleSubmit(handleResetPassword)}
            title="Resetuj hasło"
          />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
