import { get } from "lodash";
import React from "react";

import BackIcon from "src/assets/images/back.svg";
import { IUser } from "src/common/ts/user";
import InfoWithLabel from "src/components/ui/InfoWithLabel";

type Props = {
  user: IUser;
  onClosePanel: () => void;
};

const UserDetails: React.FC<Props> = ({ user, onClosePanel }) => {
  return (
    <aside className={`sidebar sidebar-right sidebar-right-order col-sm-12 col-md-3 ${user && "active"}`}>
      <div className="sidebar-content py-5 px-3">
        <img alt="go-back" className="backbtn" onClick={onClosePanel} role="button" src={BackIcon} />
        <h3 className="text-white text-center mb-5">{user?.name}</h3>
        <div className="row g-2">
          <label className="col-12 top-label">
            <section className="scroll-section">
              <InfoWithLabel label="Imię i nazwisko" value={user?.name} />
              <InfoWithLabel label="Email" value={user?.email} />
              <InfoWithLabel label="Numer telefonu" value={get(user, "phone", "-")} />
              <InfoWithLabel label="Nazwa firmy" value={get(user, "billingDetails.companyName", "-")} />
              <InfoWithLabel label="NIP" value={get(user, "billingDetails.companyId", "-")} />
              <InfoWithLabel label="Adres" value={get(user, "billingDetails.address", "-")} />
              <InfoWithLabel label="Kod pocztowy" value={get(user, "billingDetails.postalCode", "-")} />
              <InfoWithLabel label="Miasto" value={get(user, "billingDetails.city", "-")} />
            </section>
          </label>
        </div>
      </div>
    </aside>
  );
};

export default UserDetails;
