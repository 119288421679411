import React from "react";

import TableRow from "src/components/ui/TableRow";
import { getDate } from "src/utils/time";
import { IUser, UserTypeEnum } from "src/common/ts/user";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";

type Props = {
  data: IUser;
  index: number;
  onShowDetails?: (user: IUser) => void;
};

const UsersTableRow: React.FC<Props> = ({ data, index, onShowDetails }) => {
  const { name, email, type, createdAt, subscribed } = data;
  const renderUserType = () => {
    switch (type) {
      case UserTypeEnum.Admin:
        return "Administrator";
      case UserTypeEnum.User:
        return "Użytkownik";
      default:
        return "Administrator";
    }
  };

  return (
    <TableRow>
      <th scope="row">{index + 1}</th>
      <td>
        <div className="d-flex flex-column">
          <div>{name}</div>
          <a className="text-small text-muted" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </td>
      <td>
        <span className="badge bg-outline-primary">{getDate(createdAt)}</span>
      </td>
      <td>
        <div>{renderUserType()}</div>
      </td>
      <td>
        <span className={`badge bg-outline-${subscribed ? "primary" : "danger"}`}>
          {subscribed ? "Aktywna" : "Nieaktywna"}
        </span>
      </td>
      <td style={{ textAlign: "center" }}>
        <AppButton color={ButtonColorTypes.VINCI} onClick={() => onShowDetails(data)} title="Szczegóły" />
      </td>
    </TableRow>
  );
};

export default UsersTableRow;
