import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { IListParams } from "src/app/apiConfig";
import { AppRoutesEnum } from "src/common/ts/enums";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";
import PageWrapper from "src/components/ui/PageWrapper";
import Pagination from "src/components/ui/Pagination";
import Table from "src/components/ui/Table";
import { useSubscription } from "src/features/subscription/subscription.hooks";
import SubscriptionsTableRow from "src/features/subscription/SubscriptionsTableRow";
import AddSubscription from "src/features/subscription/AddSubscription";
import { ISubscription } from "src/common/ts/subscription";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";

const TABLE_HEADERS = ["#", "Nazwa i opis", "Czas trwania", "Cena", "Demo", " "];

const Subscription = () => {
  const [filteringData, setFilteringData] = useState<IListParams>({});
  const [selectedSubscription, setSelectedSubscription] = useState<Partial<ISubscription>>();
  const { fetchSubscriptions, isLoading, subscriptions } = useSubscription();

  useEffect(() => {
    fetchSubscriptions(filteringData);
  }, [filteringData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedSubscription(null);
  }, [subscriptions]);

  const renderRows = () => {
    if (!subscriptions) return null;
    return subscriptions.documents.map((d, i) => {
      return (
        <SubscriptionsTableRow
          data={d}
          index={i}
          key={d._id}
          onEditSubscription={setSelectedSubscription}
          selectedSubscriptionId={selectedSubscription?._id}
        />
      );
    });
  };

  return (
    <PageWrapper>
      <AddSubscription onClose={() => setSelectedSubscription(null)} selectedSubscription={selectedSubscription} />
      <ModuleContentContainer isSidebarActive={!!selectedSubscription}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-weight-bold m-0">Pakiety</h3>
          <AppButton color={ButtonColorTypes.VINCI} onClick={() => setSelectedSubscription({})} title="Dodaj" />
        </div>
        <hr />
        <section className="scroll-section" id="breakpointSpecificResponsive">
          <div className="card">
            <div className="card-body p-0">
              <Table headers={TABLE_HEADERS} isLoading={isLoading} rows={renderRows()} />
              <Pagination
                filteringData={filteringData}
                onChange={setFilteringData}
                route={AppRoutesEnum.Subscriptions}
                total={get(subscriptions, "total", 1)}
              />
            </div>
          </div>
        </section>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Subscription;
