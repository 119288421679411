import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import BackIcon from "src/assets/images/back.svg";
import { ISubscription } from "src/common/ts/subscription";
import AppCheckbox from "src/components/form/AppCheckbox";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import Textarea from "src/components/form/AppTextarea";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import LabeledContainer from "src/components/ui/LabeledContainer";
import AddSubscriptionSchema from "src/features/subscription/addSubscription.schema";
import { useSubscription } from "src/features/subscription/subscription.hooks";

type Props = {
  selectedSubscription: Partial<ISubscription>;
  onClose: () => void;
};

const AddSubscription: React.FC<Props> = ({ selectedSubscription, onClose }) => {
  const { addSubscription, editSubscription } = useSubscription();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors, isSubmitted },
  } = useForm<ISubscription>({
    resolver: yupResolver(AddSubscriptionSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (selectedSubscription) {
      const { name, description, duration, isDemo, price } = selectedSubscription;
      setValue("name", name);
      setValue("description", description);
      setValue("duration", duration);
      setValue("isDemo", isDemo);
      setValue("price", price);
    }
  }, [selectedSubscription, setValue]);

  const setSubscription = (data: ISubscription) => {
    const id = get(selectedSubscription, "_id");
    if (!id) {
      addSubscription(data);
    } else {
      editSubscription(id, data);
    }
  };

  return (
    <aside className={`sidebar sidebar-right col-sm-12 col-md-3 ${selectedSubscription && "active"}`}>
      <div className="sidebar-content py-5 px-3">
        <img alt="back" className="backbtn" onClick={onClose} role="button" src={BackIcon} />
        <h3 className="text-white text-center mb-5">{get(selectedSubscription, "name", "Nowy pakiet")}</h3>
        <div className="row g-2">
          <LabeledContainer customClass="col-12" title="Nazwa">
            <AppInput
              error={formErrors.name?.message}
              id="name"
              inputType={InputTypes.TEXT}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <LabeledContainer customClass="col-12" title="Opis">
            <Textarea error={formErrors.description?.message} id="description" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-12" title="Czas trwania (dni)">
            <AppInput
              error={formErrors.duration?.message}
              id="duration"
              inputType={InputTypes.NUMBER}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <LabeledContainer customClass="col-6 align-items-center d-flex">
            <AppCheckbox error={formErrors.isDemo?.message} id="isDemo" label="Pakiet demo" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6" title="Cena">
            <AppInput
              error={formErrors.price?.message}
              id="price"
              inputType={InputTypes.NUMBER}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <div className="col-12">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              customClass="col-12 mt-5"
              onClick={handleSubmit(setSubscription)}
              title="Zapisz"
            />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AddSubscription;
