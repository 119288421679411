import { IListParams } from "src/app/apiConfig";
import { useEffect } from "react";
import { toast } from "react-toastify";

import {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useLazyGetSubscriptionsQuery,
  useUpdateSubscriptionMutation,
} from "src/features/subscription/subscriptionApi";
import { ISubscription } from "src/common/ts/subscription";

export const useSubscription = () => {
  const [getSubscriptions, { data: subscriptions, isLoading, error }] = useLazyGetSubscriptionsQuery();
  const [createSubscription, { data: createSubscriptionData, isLoading: createLoading, error: createError }] =
    useCreateSubscriptionMutation();
  const [updateSubscription, { data: updateSubscriptionData, isLoading: updateLoading, error: updateError }] =
    useUpdateSubscriptionMutation();
  const [deleteSubscription, { data: deleteRes, error: deleteError }] = useDeleteSubscriptionMutation();

  useEffect(() => {
    if (error || createError || updateError || deleteError) {
      toast.error("Wystąpił błąd");
    }
  }, [error, createError, updateError, deleteError]);

  useEffect(() => {
    if (createSubscriptionData || updateSubscriptionData) {
      toast.success("Zapisano");
    }
  }, [createSubscriptionData, updateSubscriptionData]);

  useEffect(() => {
    if (deleteRes) {
      toast.success("Usunięto");
    }
  }, [deleteRes]);

  return {
    addSubscription: async (data: ISubscription) => {
      await createSubscription(data);
    },
    createLoading,
    createSubscriptionData,
    deleteSubscription: async (id: string) => {
      await deleteSubscription({ id });
    },
    editSubscription: async (id: string, data: Partial<ISubscription>) => {
      await updateSubscription({ ...data, id });
    },
    fetchSubscriptions: async (listParams: IListParams) => {
      await getSubscriptions(listParams);
    },
    isLoading,
    subscriptions,
    updateLoading,
    updateSubscriptionData,
  };
};
