import React from "react";

type Props = {
  customClass?: string;
  title?: string;
  children: React.ReactNode;
};

const LabeledContainer: React.FC<Props> = ({ customClass, title, children }) => {
  return (
    <div className={`top-label ${customClass}`}>
      {children}
      <span>{title}</span>
    </div>
  );
};

export default LabeledContainer;
