import { object, string, date, number, boolean } from "yup";

export default object({
  active: boolean().default(false),
  content: string().required("Wartość jest wymagana"),
  dayInterval: number().when("periodicity", (periodicity, schema) => {
    if (periodicity[0]) return schema.required("Wartość jest wymagana");
    return schema;
  }),
  isPush: boolean().default(false),
  isWebPush: boolean().default(false),
  name: string().required("Wartość jest wymagana"),
  periodicity: boolean().required("Wartość jest wymagana"),
  startDate: date().required("Wartość jest wymagana"),
  time: string(),
}).required();
