import { createApi } from "@reduxjs/toolkit/query/react";
import { IListParams, customBaseQuery, setFormData } from "src/app/apiConfig";
import { IBaseListResponse } from "src/common/ts/base";
import { IOrder } from "src/common/ts/order";

export const orderApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    addInvoice: build.mutation({
      invalidatesTags: ["Orders"],
      query: ({ body, id }) => ({
        body: setFormData({ invoice: body }),
        method: "POST",
        url: `/order/${id}/addInvoice`,
      }),
    }),
    getOrderInvoice: build.query<any, { id: string; fileName: string }>({
      query: ({ id, fileName }) => ({
        responseHandler: async (response) => {
          const hiddenElement = document.createElement("a");
          const url = window.URL || window.webkitURL;
          const blobPDF = url.createObjectURL(await response.blob());
          hiddenElement.href = blobPDF;
          hiddenElement.target = "_blank";
          hiddenElement.download = fileName;
          hiddenElement.click();
          return { data: null };
        },
        url: `order/${id}/getInvoice`,
      }),
    }),
    getOrders: build.query<IBaseListResponse<IOrder>, IListParams>({
      providesTags: ["Orders"],
      query: (query) => ({
        params: { ...query, "sort[name]": "asc" },
        url: `order`,
      }),
    }),
  }),
  reducerPath: "orderApi",
  tagTypes: ["Orders"],
});

export const { useAddInvoiceMutation, useLazyGetOrdersQuery, useLazyGetOrderInvoiceQuery } = orderApi;
