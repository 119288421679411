import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "src/app/apiConfig";
import { IForgotPasswordRequest } from "src/common/ts/forgotPassword.interface";

export const forgotPasswordApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    forgotPassword: build.mutation<any, IForgotPasswordRequest>({
      query: (data) => ({
        body: data,
        method: "POST",
        url: `forgotPassword`,
      }),
    }),
  }),
  reducerPath: "forgotPasswordApi",
  tagTypes: ["ForgotPassword"],
});

export const { useForgotPasswordMutation } = forgotPasswordApi;
