import React from "react";

type Props = {
  children: React.ReactNode;
};

const PageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="container p-0 h-100 col-12 position-relative">
      <div className="row g-0">
        <div className="outer-container admin rounded-lg h-100 d-flex">{children}</div>
      </div>
    </div>
  );
};

export default PageWrapper;
