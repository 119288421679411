import React from "react";

type Props = {
  label: string;
  error?: string;
  register: any;
  id: string;
};

const AppCheckbox: React.FC<Props> = ({ label, error, register, id }) => {
  return (
    <>
      <div className="form-check d-flex">
        <div className="form-check form-switch mb-0">
          <input {...register(id)} className="form-check-input" id={id} type="checkbox" />
          <label className="form-check-label text-white">{label}</label>
        </div>
      </div>
      {error && <div className="invalid-tooltip">{error}</div>}
    </>
  );
};

export default AppCheckbox;
