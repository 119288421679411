import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles/vendor/bootstrap.min.css";
import "./styles/vendor/OverlayScrollbars.min.css";
import "./styles/styles.css";
import "./styles/main.scss";

import Root from "./Root";
import store from "./app/store";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        position="bottom-right"
        rtl={false}
        theme="light"
      />
    </Provider>
  </React.StrictMode>,
);
