import { configureStore } from "@reduxjs/toolkit";

import { loginApi } from "src/features/login/loginApi";
import { forgotPasswordApi } from "src/features/forgotPassword/forgotPasswordApi";
import { resetPasswordApi } from "src/features/resetPassword/resetPasswordApi";
import { userApi } from "src/features/user/userApi";
import { orderApi } from "src/features/order/orderApi";
import { subscriptionApi } from "src/features/subscription/subscriptionApi";
import { notificationApi } from "src/features/notification/notificationApi";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(forgotPasswordApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(orderApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(notificationApi.middleware)
      .concat(userApi.middleware),
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
