import React from "react";

type Props = {
  label: string;
  value: string;
};

const InfoWithLabel: React.FC<Props> = ({ label, value }) => {
  return (
    <>
      <h4 className="text-white ps-3 mt-3">{label}</h4>
      <span className="small-title text-white ps-3 mt-3" style={{ marginLeft: 5 }}>
        {value || "-"}
      </span>
    </>
  );
};

export default InfoWithLabel;
