import moment from "moment";

export const getDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const getTime = (date: Date) => {
  return moment(date).format("HH:mm");
};

export const getTimestamp = (date: string, format: string) => {
  return moment(date, format).unix();
};
