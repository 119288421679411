import React, { useEffect, useState } from "react";
import { get } from "lodash";

import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import PageWrapper from "src/components/ui/PageWrapper";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";

import AddInvoice from "src/features/order/AddInvoice";

import { IListParams } from "src/app/apiConfig";
import { AppRoutesEnum } from "src/common/ts/enums";

import { IOrder } from "src/common/ts/order";

import OrderFilters from "./OrderFilters";
import { useOrder } from "./order.hooks";
import OrdersTableRow from "./OrdersTableRow";
import OrderDetails from "./OrderDetails";

const TABLE_HEADERS = ["#", "Numer zamówienia", "Data zakupu", "Status", "Pakiet", "Użytkownik", "Faktura", ""];

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState<Partial<IOrder>>();
  const [selectedOrderId, setSelectedOrderId] = useState<string>();
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<string>();
  const [filteringData, setFilteringData] = useState<IListParams>({});
  const { handleGetOrderInvoice, handleGetOrders, isLoading, orders } = useOrder();

  useEffect(() => {
    handleGetOrders(filteringData);
  }, [filteringData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedOrderId(null);
    setSelectedOrderNumber(null);
  }, [orders]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAddInvoicePanel = (id: string, number: string) => {
    setSelectedOrderId(id);
    setSelectedOrderNumber(number);
  };

  const onCloseInvoicePanel = () => {
    setSelectedOrderId(null);
    setSelectedOrderNumber(null);
  };

  const onShowOrderDetails = (order: Partial<IOrder>) => {
    setSelectedOrder(order);
    onCloseInvoicePanel();
  };

  const renderRows = () => {
    if (!orders) return null;
    return orders.documents.map((d, i) => {
      return (
        <OrdersTableRow
          data={d}
          index={i}
          key={d._id}
          onGetInvoice={handleGetOrderInvoice}
          onShowAddInvoice={setAddInvoicePanel}
          onShowDetails={onShowOrderDetails}
          selectedOrderId={selectedOrderId}
        />
      );
    });
  };

  return (
    <PageWrapper>
      <AddInvoice onClosePanel={onCloseInvoicePanel} orderId={selectedOrderId} orderNumber={selectedOrderNumber} />
      <OrderDetails onClosePanel={() => setSelectedOrder(null)} order={selectedOrder} />
      <ModuleContentContainer isSidebarActive={!!selectedOrderId}>
        <div className="d-flex content-header justify-content-between align-items-center">
          <h3 className="font-weight-bold m-0">Zamówienia</h3>
          <OrderFilters onChange={setFilteringData} />
        </div>
        <hr />
        <section className="scroll-section" id="breakpointSpecificResponsive">
          <div className="card">
            <div className="card-body p-0">
              <Table headers={TABLE_HEADERS} isLoading={isLoading} rows={renderRows()} />
              <Pagination
                filteringData={filteringData}
                onChange={setFilteringData}
                route={AppRoutesEnum.Orders}
                total={get(orders, "total", 1)}
              />
            </div>
          </div>
        </section>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Orders;
