import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { ILoginRequest } from "src/common/ts/login.interface";
import { AppRoutesEnum } from "src/common/ts/enums";

import { useLoginMutation } from "./loginApi";

export const useLogin = () => {
  const navigate = useNavigate();
  const [login, { error, data, isLoading }] = useLoginMutation();

  useEffect(() => {
    if (data) {
      toast.success("Zalogowano");
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("userName", data.user.name);
      navigate(AppRoutesEnum.Users);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      toast.error("Niepoprawne login lub hasło");
    }
  }, [error]);

  return {
    error,
    handleLogin: async (loginRequestData: ILoginRequest) => {
      await login(loginRequestData);
    },
    isLoading,
  };
};
