import { IBase } from "./base";

export interface IUserFilteringData {
  name?: string;
  dateFrom?: Date;
  dateTo?: Date;
  offset?: number;
  limit?: number;
}

export enum UserTypeEnum {
  Admin = 1,
  User,
}

export interface IBillingDetails {
  companyName: string;
  companyId: string;
  address: string;
  city: string;
  postalCode: string;
}

export interface IUser extends IBase {
  _id?: string;
  name: string;
  email: string;
  password: string;
  type: UserTypeEnum;
  orders?: any[];
  subscribed: boolean;
  subscribedTo: Date;
  phone: string;
  billingDetails: IBillingDetails;
}
