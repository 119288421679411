import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "src/app/apiConfig";
import { ILoginRequest } from "src/common/ts/login.interface";

export const loginApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    isAuth: build.query<any, void>({
      query: () => ({
        url: `checkAuth`,
      }),
    }),
    login: build.mutation<any, ILoginRequest>({
      query: (data) => ({
        body: data,
        method: "POST",
        url: `adminLogin`,
      }),
    }),
  }),
  reducerPath: "loginApi",
  tagTypes: ["Login"],
});

export const { useLoginMutation, useIsAuthQuery } = loginApi;
