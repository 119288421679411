import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IListParams } from "src/app/apiConfig";
import { IOrderFilteringData } from "src/common/ts/order";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import AppDatePicker from "src/components/ui/AppDatePicker";
import LabeledContainer from "src/components/ui/LabeledContainer";
import orderFiltersSchema from "src/features/order/orderFilters.schema";

type Props = {
  onChange: React.Dispatch<React.SetStateAction<IListParams>>;
};

const OrderFilters: React.FC<Props> = ({ onChange }) => {
  const { control, register, handleSubmit } = useForm<IListParams>({
    resolver: yupResolver(orderFiltersSchema),
    reValidateMode: "onChange",
  });

  const parseData = (data: IOrderFilteringData) => {
    const { dateFrom, dateTo, number } = data;

    const parsedData: IListParams = {};
    if (number) parsedData.number = number;
    if (dateFrom) parsedData["createdAt[gte]"] = moment(dateFrom).unix();
    if (dateTo) parsedData["createdAt[lte]"] = moment(dateTo).add(1, "day").unix();

    onChange(parsedData);
  };

  return (
    <div className="ms-md-3 mt-3 mt-md-0 justify-content-md-end flex-wrap row g-3 d-flex">
      <LabeledContainer customClass="col-6 col-md-3" title="Numer zamówienia">
        <AppInput id="number" inputType={InputTypes.TEXT} register={register} />
      </LabeledContainer>
      <LabeledContainer customClass="col-6 col-md-3" title="Data utworzenia od">
        <Controller control={control} name="dateFrom" render={({ field }) => <AppDatePicker field={field} />} />
      </LabeledContainer>
      <LabeledContainer customClass="col-6 col-md-3" title="Data utworzenia do">
        <Controller control={control} name="dateTo" render={({ field }) => <AppDatePicker field={field} />} />
      </LabeledContainer>
      <AppButton
        color={ButtonColorTypes.VINCI}
        customClass="col-12 col-md-3 btn-primary filtry"
        onClick={handleSubmit(parseData)}
        title="Filtruj"
      />
    </div>
  );
};

export default OrderFilters;
