export enum AppRoutesEnum {
  Login = "/login",
  ForgotPassword = "/forgotPassword",
  ResetPassword = "/resetPassword",
  Users = "/users",
  Orders = "/orders",
  Subscriptions = "/subscriptions",
  Notifications = "/notifications",
}

export enum OrderStatusEnum {
  NEW = "NEW",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELED",
}
