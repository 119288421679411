import React from "react";

type Props = {
  children: React.ReactNode;
  isSidebarActive?: boolean;
};

const ModuleContentContainer: React.FC<Props> = ({ children, isSidebarActive }) => {
  return (
    <div
      className={`main-content col-sm-12 col-md-9 position-relative bg-foreground rounded-xl-top rounded-xl-bottom d-flex ${
        isSidebarActive && "sidebar-active"
      }`}
    >
      <div className="container">
        <div className="row g-3 p-3">
          <div className="col-12 col-md-12 p-3 mt-0">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModuleContentContainer;
